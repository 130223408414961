import { createRouter, createWebHashHistory } from 'vue-router'
import Informations from '../views/Informations.vue'

const routes = [
  {
    path: '/',
    name: 'Informations',
    component: Informations
  },
  {
    path: '/informations',
    component: () => import(/* webpackChunkName: "informations" */ '../views/Informations.vue'),
    name: 'Informations',
    meta: { title: '资讯' }
  },
  {
    path: '/weeklyRecipe',
    component: () => import(/* webpackChunkName: "weeklyRecipe" */ '../views/WeeklyRecipe.vue'),
    name: 'WeeklyRecipe',
    meta: { title: '每周食谱' }
  },
  {
    path: '/userAgreement',
    component: () => import(/* webpackChunkName: "userAgreement" */ '../views/UserAgreement.vue'),
    name: 'UserAgreement',
    meta: { title: '用户协议' }
  },
  {
    path: '/privacyAgreement',
    component: () => import(/* webpackChunkName: "privacyAgreement" */ '../views/PrivacyAgreement.vue'),
    name: 'PrivacyAgreement',
    meta: { title: '用户协议' }
  },
  {
    path: '/businessReports',
    component: () => import(/* webpackChunkName: "privacyAgreement" */ '../views/BusinessReports.vue'),
    name: 'BusinessReports',
    meta: { title: '营养报告' }
  }

]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
