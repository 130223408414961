import request from '../utils/request'

export function postNewsDetail(params) {
  return request({
    url: '/news/detail',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function postWeekDishById(params) {
  return request({
    url: '/syWeekDishSet/getWeekDishById',
    method: 'post',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}



