import axios from 'axios'
// import { Notify } from 'vant'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(

  response => {
    const res = response.data
    if (res.code !== '00') {
      // Notify({
      //   message: res.message || 'Error',
      //   type: 'warning',
      //   duration: 5 * 1000
      // })
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      if (response.config.url.endsWith('/syUser/login')) {
        localStorage.setItem('token', response.headers.token)
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Notify({
    //   message: error.message,
    //   type: 'danger',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
