<template>
  <div>
    <van-loading v-if="isLoading" type="spinner" />
    <div v-else style="padding: 12px" v-html="content"></div>
  </div>
</template>

<script>
import { postNewsDetail } from '../api/table'
import { Notify } from 'vant'

export default {
  name: 'Informations',
  components: {
  },
  data() {
    return {
      newId: '',
      isLoading: true,
      content: null
    }
  },

  methods: {
    async initData() {
      try {
        const { data } = await postNewsDetail({
          id: this.newId
        })

        this.content = data?.content
        this.isLoading = false

        console.log('data????', data)
      } catch (error) {
        console.log(error)
        // Notify({ type: 'warning', message: error, duration: 2000 })
      }
    }
  },
  created() {
    console.log('id===', this.$route.query.id)
    this.newId = this.$route.query?.id ?? ''
    if (!this.newId) {
      Notify({ type: 'warning', message: '缺少参数ID', duration: 2000 })
    } else {
      this.initData()
    }
  }
}
</script>
